import { createSelector } from 'reselect'
import { defaultCurrencyRate, defaultLocaleCountryCode } from 'lib/i18'
import { Locale } from 'types/Shared/Locale'
import 'types/Shared/Locale'
import { Shared } from 'types/Shared'
import 'types/Shared'
import { getShared } from './helpers'
export const getLocale = createSelector(
  [getShared],
  (shared: Shared): Locale => shared.locale || defaultCurrencyRate
)
export const getLocaleIsReady = createSelector(
  [getLocale],
  ({ availableCountries, availableCurrencies }): boolean =>
    !!availableCountries && !!availableCurrencies
)

export const getLocaleCountryConflict = createSelector(
  [getLocale],
  (locale): boolean => locale.countryConflict || false
)

export const getLocaleCountryCode = createSelector(
  [getLocale],
  (locale): string => locale.countryCode || defaultLocaleCountryCode
)
export const getLocaleRegionCode = createSelector(
  [getLocale],
  (locale): string => locale.regionCode || ''
)
export const getLocaleCity = createSelector([getLocale], (locale): string => locale.city || '')
export const getAvailableCurrencies = createSelector([getLocale], ({ availableCurrencies }) => {
  return availableCurrencies || {}
})
export const getAvailableCurrenciesData = createSelector(
  [getAvailableCurrencies],
  (availableCurrencies) => Object.values(availableCurrencies) || []
)
export const getCurrenciesDropdown = createSelector(
  [getAvailableCurrenciesData],
  (availableCurrencies) => {
    const data = availableCurrencies.reduce((results, { currencyName, currencyCode, locale }) => {
      return { ...results, [currencyCode]: `${currencyName} (${currencyCode})` }
    }, {})
    return data // { 'en-AU': 'Austraila', ... }
  }
)
export const getIsHideCurrency = createSelector(
  [getLocale],
  (locale): boolean => locale.hide || false
)
export const getAvailableCountries = createSelector(
  [getLocale],
  ({ availableCountries }) => availableCountries || {}
)
export const getAvailableCountriesData = createSelector(
  [getAvailableCountries],
  (availableCountries) => Object.keys(availableCountries) || []
)
export const getLocaleOrder = createSelector(
  [getAvailableCountriesData],
  (availableCountries): Array<string> => {
    const data = Object.keys(availableCountries).map((locale) => `en-${locale.toLowerCase()}`)
    return data // [ 'en-au', ... }
  }
)
